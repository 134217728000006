@media print {

    body,  .mantine-AppShell-navbar * {
        visibility:hidden;
        overflow: hidden;
      }

    button * {
        visibility: hidden;
    }

    body .modal {
        visibility:visible;
        overflow: visible !important;
        display:block;
        position: absolute;
      }
}
