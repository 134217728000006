.th {
    padding: 0 !important;
}

.control {
    width: 100%;
}

.icon {
    width: rem(21px);
    height: rem(21px);
    border-radius: rem(21px);
}