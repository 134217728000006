.referralOrPromoCheckbox {
  &:checked {
    border: 0px;
    display: flex;
  }
}

.referralGroupRoot[data-error="true"] {
  border: 1px solid red;
  border-radius: var(--mantine-radius-sm);
}
