.root {
  position: relative;
  background-color: var(--mantine-color-gray-0);
  width: fit-content;
  border-radius: var(--mantine-radius-md);
  border: 1px solid var(--mantine-color-gray-3);
}

.control {
  line-height: 1;
  border-radius: var(--mantine-radius-sm);
  transition: color 100ms ease;

  &[data-active] {
    color: var(--mantine-color-white);
    background-color: var(--mantine-color-dark-6);
  }
}

.controlLabel {
  position: relative;
  z-index: 1;
}