html,
body {
  margin: 0 auto;
  /* background-color: #f9f7f4; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

@media only screen and (min-width: 720px) {

  /* NAV BAR ———————————————————— */
  #navBar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 24px;
    /* background-color: #f9f7f4; */
    z-index: 1;
  }

  .logo {
    width: 130px;
    height: 40px;
  }

  /* HERO ———————————————————— */
  #hero {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 50px 0px 80px 0px;
    width: 100%;
  }

  #heroContent {
    max-width: 880px;
    width: 65%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  #heroImage {
    min-width: 0vh;
    max-width: 65%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    margin-top: 24px;
  }

  .callout {
    background: #F8F7F4;
    mix-blend-mode: multiply;
    border-radius: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 32px 32px 32px 32px;
    max-width: 880px;
    width: 65%;
    margin: auto;
    color: #111111 !important;
    text-decoration: none;
    height: 115px;
  }

  .callout:hover {
    background-color: #F1EFE9;
  }

  .calloutText {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .calloutImage {
    width: 30%;
  }

  .creatorHint {
    background-image: url("../assets/images/creatorHint.svg");
    background-size: initial;
    background-repeat: no-repeat;
    margin-right: 12%;
    margin-top: -16px;
    align-self: flex-end;
    width: 40%;
    height: 88px;
  }

  /* CUSTOMER LOGOS ——————————————*/

  .customerLogoContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 80%;
    margin: 80px auto 160px auto;
    max-width: 1048px;
    align-items: center;
  }

  .customerLogoRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 40px;
  }

  .customerLogo {
    width: 20%;
    height: auto;
    background-size: contain;
  }

  /* PRODUCT FEATURES ———————————— */

  .productFeatureWrapper {
    width: 80%;
    margin: 120px auto;
    max-width: 1048px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  .productFeature {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
  }

  .productFeatureContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }

  .productFeatureImage {
    width: 50%;
  }

  /* QUOTES ———————————— */

  .customerTestimonial {
    margin: 0 auto;
    max-width: 1048px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
  }

  .customerImage {
    border-radius: 400px;
    width: 240px;
    height: 240px;
  }

  .customerQuote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 32px;
    gap: 24px;
    background: #f8f7f4;
    mix-blend-mode: multiply;
    border-radius: 24px 24px 24px 0px;
    flex-grow: 1;
    height: fit-content;
  }

  /* CTA ———————————— */

  .ctaModule {
    max-width: 100%;
    padding: 160px 0px 160px 0px;
    background-color: #111111;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #292929;
  }

  /* FOOTER ———————————— */

  footer {
    max-width: 100%;
    padding: 48px;
    background-color: #111111;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .footerLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    padding: 32px 32px;
  }

  .footerCol {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
  }

  #footerLogo {
    background: url("logo-white.svg");
    background-size: contain;
  }

  /* BUTTONS ———————————————————— */
  .buttonGroup {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-self: center;
  }

  button {
    border-radius: 40px;
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
  }

  .primary {
    background: #0074e8;
    color: white;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
  }

  .primary:hover {
    scale: 102%;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
    background-color: #0059b2;
  }

  .secondary {
    outline: inset 1px solid #111;
    color: #111111;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
    mix-blend-mode: multiply;
  }

  .secondary:hover {
    scale: 102%;
    background: #d9d9d9;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
    mix-blend-mode: multiply;
  }

  .tertiary {
    background: none;
    color: #111111;
    transition: linear;
    transition-duration: 250ms;
    background-color: #f9f7f4;
  }

  .tertiary:hover {
    scale: 102%;
    transition: linear;
    transition-duration: 250ms;
    background-color: #e9e9e9;
  }

  /* TYPE ———————————————————— */

  .display {
    font-family: SF Pro Display;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 0em;
    margin: 0 0 0 0;
  }

  .largeBody {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0 0 8px 0;
  }

  .body {
    font-size: 16;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 0;
  }

  .header {
    font-size: 28px;
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0em;
    margin: 0;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0;
  }

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }

  /* OTHERS ———————————————————— */
  .tag {
    align-items: center;
    padding: 10px 16px;
    border: 1px solid #111111;
    color: #111111;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
  }

  .content {
    width: 80%;
    max-width: 1048px;
  }

  .spacer16 {
    width: 16px;
    height: 16px;
    margin: auto;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #292929;
  }

  a {
    color: #0074e8;
  }

  a:visited {
    color: #0074e8;
  }

  .footerLink {
    color: #767676;
    text-decoration: none;
  }

  .footerLink:visited {
    color: #767676;
  }

  .footerLink:hover {
    text-decoration: underline;
    color: white;
  }

  .socialCopyright {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .socialIcons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .socialIcons i {
    padding: 8px;
    background-color: hsla(0, 0%, 100%, 0.1);
    font-size: 32px;
    border-radius: 24px;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
  }

  .socialIcons i:hover {
    scale: 102%;
    background-color: hsla(0, 0%, 100%, 0.15);
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
  }
}

@media only screen and (max-width: 719px) {

  /* NAV BAR ———————————————————— */
  #navBar {
    max-width: 100%;
    background-color: #f9f7f4;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .logo {
    width: 130px;
    height: 40px;
  }

  /* #navBarLogo {
    background: url("logo-black.svg");
    background-size: contain;
    shape-rendering: geometricPrecision;
  } */

  /* HERO ———————————————————— */
  #hero {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 20px 0px 64px 0px;
    width: 100%;
  }

  #heroContent {
    max-width: 1048px;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  #heroImage {
    min-width: 0vh;
    max-width: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .callout {
    background: #F8F7F4;
    mix-blend-mode: multiply;
    border-radius: 24px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding: 32px 32px 32px 32px;
    max-width: 880px;
    width: 70%;
    margin: auto;
    color: #111111 !important;
    text-decoration: none;
  }

  .calloutText {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .calloutImage {
    width: 100%;
    height: auto;
  }

  .creatorHint {
    background-image: url("../assets/images/creatorHintMobile.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 80px;
    margin-right: 10%;
    margin-top: -8px;
  }

  /* CUSTOMER LOGOS ——————————————*/

  .customerLogoContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 90%;
    margin: 56px auto;
    max-width: 960px;
  }

  .customerLogoRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 8px;
    gap: 24px;
    align-items: center;
  }

  .customerLogo {
    width: 45%;
    height: auto;
    background-size: contain;
    align-self: center;
  }

  /* PRODUCT FEATURES ———————————— */

  .productFeatureWrapper {
    width: 90%;
    margin: 56px auto;
    max-width: 1048px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  .productFeature {
    display: flex;
    flex-direction: column-reverse;
    gap: 32px;
    justify-content: space-between;
  }

  .productFeatureContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }

  .productFeatureImage {
    width: 100%;
  }

  /* QUOTES ———————————— */

  .customerTestimonial {
    margin: 0 auto;
    max-width: 1048px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    background: #f8f7f4;
    mix-blend-mode: multiply;
    border-radius: 24px 24px 24px 24px;
    padding: 24px;
  }

  .customerImage {
    border-radius: 400px;
    width: 56px;
    height: 56px;
  }

  .customerQuote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    background: #f8f7f4;
    mix-blend-mode: none;
    flex-grow: 1;
    height: fit-content;
  }

  /* CTA ———————————— */

  .ctaModule {
    max-width: 100%;
    padding: 160px 0px 160px 0px;
    background-color: #111111;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #292929;
  }

  /* FOOTER ———————————— */

  footer {
    max-width: 100%;
    padding: 32px 24px;
    background-color: #111111;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .footerLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    padding: 0px 0px;
  }

  .footerCol {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
  }

  #footerLogo {
    background: url("logo-white.svg");
    background-size: contain;
  }

  /* BUTTONS ———————————————————— */
  .buttonGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    justify-content: center;
  }

  button {
    border-radius: 40px;
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 500;
  }

  .primary {
    background: #0074e8;
    color: white;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
  }

  .primary:hover {
    scale: 105%;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
    background-color: #0059b2;
  }

  .secondary {
    outline: inset 1px solid #111;
    color: #111111;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
    mix-blend-mode: multiply;
  }

  .secondary:hover {
    scale: 102%;
    background: #d9d9d9;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
    mix-blend-mode: multiply;
  }

  .tertiary {
    background: none;
    color: #111111;
    transition: linear;
    transition-duration: 250ms;
    background-color: #f9f7f4;
  }

  .tertiary:hover {
    scale: 105%;
    transition: linear;
    transition-duration: 250ms;
    background-color: #e9e9e9;
  }

  /* TYPE ———————————————————— */

  .display {
    font-size: 30px;
    font-weight: 800;
    line-height: auto;
    letter-spacing: 0em;
    margin: 0 0 0 0;
  }

  .largeBody {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0 0 8px 0;
  }

  .body {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    margin: 0;
  }

  .header {
    font-size: 24px;
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0em;
    margin: 0;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin: 0;
  }

  .label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }

  /* OTHERS ———————————————————— */
  .tag {
    align-items: center;
    padding: 10px 16px;
    border: 1px solid #111111;
    color: #111111;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
  }

  .content {
    width: 80%;
    max-width: 1024px;
  }

  .spacer16 {
    width: 16px;
    height: 16px;
    margin: auto;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #292929;
  }

  a {
    color: #0074e8;
  }

  a:visited {
    color: #0074e8;
  }

  .footerLink {
    color: #767676;
    text-decoration: none;
  }

  .footerLink:visited {
    color: #767676;
  }

  .footerLink:hover {
    text-decoration: underline;
    color: white;
  }

  .socialCopyright {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
  }

  .socialIcons {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  .socialIcons i {
    padding: 8px;
    background-color: hsla(0, 0%, 100%, 0.1);
    font-size: 24px;
    border-radius: 24px;
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
  }

  .socialIcons i:hover {
    scale: 102%;
    background-color: hsla(0, 0%, 100%, 0.15);
    transition: cubic-bezier(0.7, -0.4, 0.4, 1.4);
    transition-duration: 250ms;
  }
}